.popup {
    $oswaldBold : 'oswald-bold';
    $openSansCondesedLight : 'OpenSans Condlight';

    $space-top: 53px;

    &-base {
        display: none;
        &.active {
            display: block;
        }
    }

    &-bg {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(black, .8);
        z-index: 999999999;

        cursor: pointer !important;

    }

    &-content {

        &__box {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            background-color: #990000;
            text-align: center;
        }

        &__header {
            align-self: flex-start;
            padding-bottom:$space-top - 12px;
            text-align: center;
            font-family: $oswaldBold;
            color: #fefefe;
            font-size: 49px;

        }

        &__img {
            $margin: 40px;
            border: 2px solid #12110e;
            margin-left: $margin;
            margin-right: $margin;
            margin-top: -$space-top;
        }

        &__footer {
            padding-top: 12px;
            padding-bottom: 9px;
            font-size: 25px;
            color: #fefefe;
            font-family: $openSansCondesedLight;
        }
    }
}